module.exports = {
  defaultTitle: 'Omar HARRAS',
  author: 'Omar HARRAS',
  url: 'https://omarharras.dev',
  legalName: 'Omar HARRAS',
  defaultDescription: 'I’m Omar and I’m a software engineer!',
  socialLinks: {
    github: 'https://github.com/lehomard',
    linkedin: 'https://www.linkedin.com/in/omar-harras-237076b6/',
  },
  googleAnalyticsID: 'UA-88875900-4',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
    facebook: 'appId',
    twitter: '@johnDoe',
  },
  address: {
    city: 'Paris',
    region: 'Region',
    country: 'France',
    zipCode: 'ZipCode',
  },
  contact: {
    email: 'email',
    phone: 'phone number',
  },
  foundingDate: '2020',
  recaptcha_key: '6Lcs6lQUAAAAAEwhNH2IsobIe2csdda4TU3efpMN',
};
